import { QueryParams } from '@aid/shared/types/interfaces/query-params.interface';
import { TypeOfCustomer } from '@aid/customers/types/enums';

export class InsuranceQueryParams extends QueryParams {
  provider?: number;
  insurance_type?: number;
  customer_specifications?: string;
  supervisor?: number;
  customer_type?: TypeOfCustomer;
}
