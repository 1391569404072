import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { CustomerFilters } from '@aid/customers/types/classes';
import { CustomerQueryParams } from '@aid/customers/types/classes/customer-query-params.class';
import { MembersService } from '@aid/members/services';
import {
  InsuranceTypeService,
  ProvidersService
} from '@aid/administration/services';
import { map, tap } from 'rxjs/operators';
import { Member } from '@aid/members/types/classes';
import { InsuranceType, Provider } from '@aid/administration/types/classes';
import {
  InsuranceFilters,
  InsuranceQueryParams
} from '@aid/insurances/types/classes';

@Injectable()
export class InsurancesFilterService {
  private filter = new BehaviorSubject<InsuranceFilters>(null);

  private sidenavOpened = new BehaviorSubject<boolean>(false);

  constructor(
    private providersService: ProvidersService,
    private insuranceTypeService: InsuranceTypeService
  ) {}

  applyFilter(filter: InsuranceFilters) {
    this.filter.next(filter);
  }

  get sidenavOpened$(): Observable<boolean> {
    return this.sidenavOpened.asObservable();
  }

  toggleSidenav() {
    this.sidenavOpened.next(!this.sidenavOpened.value);
  }

  get filtersValue(): InsuranceFilters {
    return this.filter.value;
  }

  /**
   * Create Filter from Query Params
   * If the current filter has the same insuranceType, provider, or agent we take the them
   * from current filter
   * If not we get the details from the API
   */
  createFilterFromParams(
    params: InsuranceQueryParams
  ): Observable<InsuranceFilters> {
    const currentFilter = this.filtersValue;
    const filter = InsuranceFilters.createFromParams(params, currentFilter);

    const observables: Observable<{
      key: string;
      value: Member | Provider | InsuranceType;
    }>[] = [];

    if (params.provider && !filter.provider) {
      observables.push(
        this.providersService.get(params.provider).pipe(
          map(value => {
            return {
              key: 'provider',
              value
            };
          })
        )
      );
    }

    if (params.insurance_type && !filter.insuranceType) {
      observables.push(
        this.insuranceTypeService.get(params.insurance_type).pipe(
          map(value => {
            return {
              key: 'insuranceType',
              value
            };
          })
        )
      );
    }

    if (observables.length > 0) {
      return combineLatest(observables).pipe(
        tap(values => {
          const provider = values.find(value => value.key === 'provider');
          filter.provider = provider ? (provider.value as Provider) : null;

          const insuranceType = values.find(
            value => value.key === 'insuranceType'
          );
          filter.insuranceType = insuranceType
            ? (insuranceType.value as InsuranceType)
            : null;
          this.applyFilter(filter);
        }),
        map(() => this.filtersValue)
      );
    } else {
      this.filter.next(filter);
      return of(filter);
    }
  }
}
